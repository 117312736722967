
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";

import { useToast } from "vue-toastification";
import { ref } from "yup";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import moment from "moment";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    Loading,
    Multiselect,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      tableData: [],
      listData: [],
      listCompany: [],
      listSekolah: [],
      listCategory: [],
      listStatus: [],
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,
      companyName: "",

      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },
      dir: 1,

      sorting: "sort=name&dir=1",

      detail: {
        createTime: "",
        creatorId: "",
        updateTime: "",
        editorId: "",
        editorName: "",
        creatorName: "",
        executorName: "",
        lastEditorNfcId: "",
        lastUpdateNfcId: "",
        dateTimeUpdate: "",
        deleteBy: "",
        deleteTime: "",
        cardId: "",
        companyId: "",
        userId: "",
        name: "",
        isDelete: false,
        active: false,
        accountNumber: "",
        accountId: "",
        callerName: "",
        callerId: "",
        tags: "",
        nfcId: "",
      },

      json_fields: {
        callerName: "callerName",
        callerId: "callerId",
        tags: "tags",
      },

      nama: "",
      tag: "",
      categoryId: "",
      status: "",
      companyId: "",

      idtext: "",
      role: "",
      // elements:"",

      aksesDownload: false,
      aksesEditNFCID: false,

      showFilter: false,

      nisFilter: '',
      timer: 0,
    };
  },

  beforeMount() {
    // this.getListSekolah();
    this.role = JSON.parse(localStorage.getItem("user_account")!).role;
    if (this.role == "ADMIN_SCHOOL") {
      this.companyId = JSON.parse(
        localStorage.getItem("user_account")!
      ).schoolId;
      this.getData(this.paging.size, this.paging.page);
    }

    console.log(this.role);
    this.cekRoleAkses();
    this.getListCompany();
    // setCurrentPageBreadcrumbs("Data Produk", ["Data"]);
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    sortnfc() {
      if (this.dir == 1) {
        this.sorting = "sort=nfc_id&dir=" + this.dir;
        this.dir = -1;
      } else {
        this.sorting = "sort=nfc_id&dir=" + this.dir;
        this.dir = 1;
      }
      this.getData(this.paging.size, this.paging.page);
    },
    cekRoleAkses() {
      switch (this.role) {
        case "SUPER_ADMIN":
          this.aksesDownload = true;
          this.aksesEditNFCID = true;
          break;
        case "SALES_LEADER":
          this.aksesDownload = true;
          this.aksesEditNFCID = true;
          break;
        case "SALES_AREA":
          this.aksesDownload = true;
          this.aksesEditNFCID = true;
          break;
        case "ADMIN_CARD":
          this.aksesDownload = true;
          this.aksesEditNFCID = true;
          break;
        case "ADMIN_SCHOOL":
          this.aksesDownload = true;
          this.aksesEditNFCID = true;
          break;
        case "IMPLEMENTATOR_LEADER":
          this.aksesDownload = true;
          this.aksesEditNFCID = false;
          break;
        case "IMPLEMENTATOR_MEMBER":
          this.aksesDownload = true;
          this.aksesEditNFCID = false;
          break;
      }
    },
    getData(size, page) {
      store.dispatch(Actions.VERIFY_AUTH);
      this.isLoading = true;

      let companyId = "";
      let nama = "";
      let tag = "";
      let valcategoryId = "";
      let nisFilter = ""
      let status = ""
      if (this.categoryId) {
        valcategoryId = this.categoryId;
      }
      if (this.companyId) {
        companyId = this.companyId + "/";
      }
      if (this.nama) {
        nama = "&callerName=" + this.nama;
      } else {
        nama = "&callerName=";
      }
      if (this.tag) {
        tag = "&tag=" + this.tag;
      } else {
        tag = "&tag=";
      }
      if (this.status) {
        status = "&mapping=" + this.status;
      } else {
        status = "&mapping=";
      }
      if (this.nisFilter) {
        nisFilter = "callerId=" + this.nisFilter;
      } else {
        nisFilter = "callerId";
      }
      var office = "";
      if (this.role == "ADMIN_SCHOOL") {
        office = "";
        companyId = "";
      } else {
        office = "office/" + companyId;
      }
      console.log(companyId, "sdj");
      ApiService.getWithoutSlug(
        "crmv2/main_card/card_mapping/" +
        office +
        // companyId +
        "all?" +
        nisFilter +
        status +
        nama +
        "&name=" +
        tag +
        "&nfcId=" +
        "&page=" +
        page +
        "&size=" +
        size +
        "&" +
        this.sorting
      )
        .then(({ data }) => {
          this.tableData = data.content;
          this.listData = data.content;
          localStorage.setItem("listData", JSON.stringify(data.content));
          this.paging.page = data.page;
          this.paging.size = data.size;
          this.paging.totalElements = data.totalElements;
          this.paging.totalPages = data.totalPages;
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },
    getDetail(idDetail) {
      store.dispatch(Actions.VERIFY_AUTH);
      var office = ""
      if (this.role == "ADMIN_SCHOOL") {
        office = "";
      } else {
        office = "office/" + this.companyId + "/";
      }
      ApiService.getWithoutSlug(
        "crmv2/main_card/card_mapping/" + office + idDetail
        // "crmv2/main_card/card/" + idDetail
      )
        .then((res) => {
          this.detail = res.data;
          this.detail.createTime = this.dateTime(res.data.createTime);
          this.detail.updateTime = this.dateTime(res.data.updateTime);
          this.detail.dateTimeUpdate = this.dateTime(res.data.lastUpdateNfcId);
          //   this.detail.imageshow = res.data.imgUrl[0];

          console.log(this.detail);

          this.isLoading = false;
        })
        .catch((e) => {
          //   toast.error(e.res.data.detail);

          this.isLoading = false;
        });
    },
    getListKategori() {
      ApiService.getWithoutSlug("crmv2/main_order/product/combo_catagory").then(
        ({ data }) => {
          this.listCategory = data;
          localStorage.setItem("listCategory", JSON.stringify(data));
        }
      );
    },
    getListSekolah() {
      ApiService.getWithoutSlug("crmv2/main_school/school/school").then(
        ({ data }) => {
          this.listSekolah = data;
          localStorage.setItem("listSchool", JSON.stringify(data));
        }
      );
    },
    getListCompany() {
      ApiService.getWithoutSlug("crmv2/main_card/util/combo_company").then(
        ({ data }) => {
          this.listCompany = data;
          localStorage.setItem("listCompany", JSON.stringify(data));
        }
      );
    },

    pilihKategori(event) {
      // console.log(event.target.value);
      console.log(event);
      // this.companyId = event;
      if (event) {
        this.categoryId = event;
      } else {
        this.categoryId = "";
      }
      // this.getQtyStudent();
      localStorage.setItem("sekolahId", JSON.stringify(event));
      this.getData(this.paging.size, this.paging.page);
    },


    pilihCompany(event) {
      // console.log(event.target.value);


      // this.schoolId = event;
      if (event) {
        var a = <any>[]
        a = this.listCompany
        a.forEach(element => {
          if (element._id == event) {
            this.companyName = element.name
            console.log(this.companyName)
          }
        });
        this.companyId = event;
        // localStorage.setItem("companyId", JSON.stringify(event));
        this.getData(this.paging.size, this.paging.page);
      } else {
        this.companyId = "";
        this.tableData = []
      }
      // this.getQtyStudent();

    },
    clickview(val) {
      this.getDetail(val.id);
    },

    autoTab(input, len, e, index1) {
      console.log(input);
      console.log(len);
      console.log(e);
      console.log(index1);
      // const index = this.findIndex(e.target);
      // const index = this.findIndex(e.target);
      // console.log(index);
      this.moveFocus(index1 + 1);
      // if (input.value.length >= len) {
      //     this.moveFocus(index + 1);
      // }
    },

    findIndex(target) {
      return [].findIndex.call(this.elements, (e) => e === target);
    },

    moveFocus(index) {
      console.log(index);
      console.log(document.getElementsByClassName("input-items")[index]);
      let text = document.getElementsByClassName("input-items")[index];
      // text.focus();
      // if (this.elements[index]) {
      //     this.elements[index].focus();
      // }
    },

    elements() {
      return document.getElementsByClassName("input-items");
    },

    searchItemTableData(id, myArray) {
      for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].id === id) {
          return myArray[i];
        }
      }
    },
    searchItemNextTableData(id, myArray) {
      for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].id === id) {
          var n = i + 1;
          if (n >= myArray.length) {
            return myArray[i];
          } else {
            return myArray[n];
          }
        }
      }
    },

    setID(val) {
      return "textfield_" + val;
    },

    onChangeNFC(item) {
      const toast = useToast();
      this.isLoading = true;
      if (item.nfcId === "") {

        toast.error("NFC ID Kosong");
        return this.isLoading = false;
      } else {
        store.dispatch(Actions.VERIFY_AUTH);

        var listdataold = JSON.parse(localStorage.getItem("listData")!);

        var getolditem = this.searchItemTableData(item.id, listdataold);
        var getnextitem = this.searchItemNextTableData(item.id, listdataold);
        console.log(getolditem.nfcId);
        console.log(getnextitem.nfcId);
        var office = ""
        if (this.role == "ADMIN_SCHOOL") {
          office = "";
        } else {
          office = "office/" + item.companyId + "/";
        }

        ApiService.PutMethodWithoutData(
          "crmv2/main_card/card_mapping/" +
          office +
          "mapping?callerId=" +
          item.callerId +
          "&nfcId=" +
          item.nfcId
          // "crmv2/main_card/card/mapping?companyId=" +
          //   item.companyId +
          //   "&callerId=" + item.callerId +
          //   "&nfcId=" + item.nfcId
        )
          .then((res) => {
            toast.success("Berhasil Update NFC ID");

            this.idtext = "textfield_" + getnextitem.id;

            this.isLoading = false;

            console.log(this.idtext);
            const input = document.getElementById(
              this.idtext
            )! as HTMLInputElement;
            // input.value =  getolditem.nfcId;
            console.log(input);
            // input.focus();
            // input.select();

            let promise2 = new Promise((resolve, reject) => {
              setTimeout(() => input.select(), 1000);
            });
          })
          .catch((e) => {
            toast.error(e.response.data.detail);
            item.nfcId = getolditem.nfcId;

            const input = document.getElementById(
              "textfield_" + item.id
            )! as HTMLInputElement;

            let promise2 = new Promise((resolve, reject) => {
              setTimeout(() => input.select(), 1000);
            });

            // input.focus();

            this.isLoading = false;
          });
      }
    },

    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize(e) {
      if (this.companyId) {
        // companyId = this.companyId;
        const page = this.paging.page;
        this.getData(this.paging.size, page);
      }
    },
    changeNIS(e) {
      clearTimeout(this.timer) //this timer 0 - set 0 atau clear jika ada input lagi

      this.timer = setTimeout(() => {

        // console.log('keyUpStop!!!->', this.nisFilter);

        const page = this.paging.page;
        this.getData(this.paging.size, page);
      }, 1000)
    },
    changeStatus(e) {
      clearTimeout(this.timer) //this timer 0 - set 0 atau clear jika ada input lagi

      this.timer = setTimeout(() => {

        // console.log('keyUpStop!!!->', this.nisFilter);

        const page = this.paging.page;
        this.getData(this.paging.size, page);
      }, 1000)
    },

    handleBlobResponse(blobFileData, extension, nama) {
      const url = window.URL.createObjectURL(new Blob([blobFileData]));
      const link = document.createElement("a");
      const d = moment().format('DD-MMMM-YYYY');

      link.href = url;
      link.setAttribute("download", "CardMapping_" + d + "_" + nama + "." + extension); // nama file dan extension sesuaikan dengan file yang di download
      document.body.appendChild(link);
      link.click();
    },
    changeModal() { },

    dateTime(val) {
      if (val) {
        let text = val;
        let result = text.includes("T");
        let jam = "";
        if (result == true) {
          // let text = "2022-04-05T09:45:32.719896";
          let val = text.split("T");
          let subst = val[1].substr(0, 8);
          jam = subst;
        } else {
          jam = "hh:mm";
        }
        return moment(val).format("YYYY-MM-DD " + jam);
      } else {
        return "-";
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    cekStatusNFC(val) {
      let length = val.length;
      if (length > 20) {
        return "Belum";
      } else {
        return "Sudah";
      }
    },

    download() {
      const toast = useToast();
      let nama = ""
      var tag = ""
      var namaSekolah = ""
      var nis = ""
      var status = ""
      if (this.nisFilter) {
        nis = "&caller_id=" + this.nisFilter;
      } else {
        nis = "&caller_id";
      } if (this.status) {
        status = "&mapping=" + this.status;
      } else {
        status = "&mapping=";
      }

      if (this.nama) {
        nama = "name=" + this.nama;
      } else {
        nama = "name=";
      }
      if (this.tag) {
        tag = "&tags=" + this.tag;
      } else {
        tag = "&tags=";
      }
      if (this.companyId) {
        this.isLoading = true;
        var office = ""
        if (this.role == "ADMIN_SCHOOL") {
          office = "";
          namaSekolah = JSON.parse(localStorage.getItem("user_account")!).name;
        } else {
          office = "office/" + this.companyId + "/";
          namaSekolah = this.companyName
        }
        ApiService.getDownload(
          "crmv2/main_card/card_mapping/" + office + "download?" + nama + tag + nis + status
          // "crmv2/main_card/card/download/" +
          // this.companyId
        )
          .then((response) => {
            console.log(response)
            this.handleBlobResponse(response.data, "xlsx", namaSekolah);
            toast.success("Sukses Unduh File");
            this.isLoading = false;
          })
          .catch((error) => {
            console.log(error);
            toast.error("Gagal Unduh File");
            this.isLoading = false;
          });
      } else {
        toast.error("Pilih Company Dahulu");
      }
    },

    //event
    eventDelaySearch(e) {
      clearTimeout(this.timer) //this timer 0 - set 0 atau clear jika ada input lagi

      this.timer = setTimeout(() => {
        const page = this.paging.page;
        this.getData(this.paging.size, page);
      }, 1000)
    },
    showHideFilter() {
      this.showFilter = !this.showFilter;
    },
  },
});
